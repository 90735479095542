<style>
@media screen and (max-width: 600px) {
  #app {
    font-size: 16px;
  }
}
@media screen and (min-width: 601px) {
  #app {
    font-size: 20px;
  }
}
</style>

<template>
  <v-app>
    <page-header />
    <v-main>
      <router-view></router-view>
    </v-main>
    <page-footer />
  </v-app>
</template>

<script>
import PageFooter from './components/PageFooter.vue'
import PageHeader from './components/PageHeader.vue'

export default {
  name: 'App',
  components: {
    PageFooter,
    PageHeader
  }
}
</script>
