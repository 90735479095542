<template>
  <v-container>
    <div>Sabine Pülsch</div>
    <div>Westsid 8</div>
    <div>27432 Bremervörde</div>
    <div>Deutschland</div>

    <div class="mt-4">Tel.: 0175-3333235</div>
    <div>E-Mail: sabine.puelsch@ewetel.net</div>

    <div class="mt-4">Plattform der EU-Kommission zur Online-Streitbeilegung: <a href="https://ec.europa.eu/odr">https://ec.europa.eu/odr</a></div>

    <div class="mt-4">Wir sind zur Teilnahme an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle weder verpflichtet noch bereit.</div>
  </v-container>
</template>

<script>

export default {
  name: 'ImprintView',
}
</script>
