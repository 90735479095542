<style scoped>
@media screen and (max-width: 600px) {
  .header-bar:deep(a) {
    font-size: 20px;
  }
}
@media screen and (min-width: 601px) {
  .header-bar:deep(a) {
    font-size: 28px;
  }
}
.header-bar:deep(a) {
  text-decoration: none;
}
.header-bar:deep(img) {
  object-position: bottom;
}
</style>

<template>
  <v-app-bar class="header-bar" image="img/header.jpg">
    <v-app-bar-title class="ml-0">
      <v-container>
        <router-link :to="{name: 'home'}">
          <span class="text-white">Ferienwohnung Pülsch</span>
        </router-link>
      </v-container>
    </v-app-bar-title>
  </v-app-bar>
</template>

<script>
export default {
  name: 'PageHeader',
}
</script>
