<style scoped>
.image-gallery {
  background-image: linear-gradient(#FFFFFF80, #FFFFFF80) ,url("/public/img/gallery_bg.jpg");
  background-size: cover;
}
</style>

<template>
  <v-carousel class="image-gallery rounded" cycle>
    <v-carousel-item
      v-for="(item, key) in items"
      :key="key"
      :src="`img/${item}`"
    ></v-carousel-item>
  </v-carousel>
</template>

<script>
export default {
  name: 'ImageGallery',
  data() {
    return {
      items: [
        '20230429_104144.jpg',
        '20230502_105355.jpg',
        '20221127_2.jpg',
        '20221127_3.jpg',
        '20221127_4.jpg',
        '20221127_5.jpg',
        '20221127_6.jpg',
        '20230614_185527.jpg',
        '20230614_185608.jpg',
        '20230614_185033.jpg',
        '20230614_185126.jpg',
        '20230502_105731.jpg',
        '20230502_105438.jpg',
        '20230502_105458.jpg',
        '20230429_104333.jpg',
        '20230504_074108.jpg',
      ]
    };
  }
}
</script>
