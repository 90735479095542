<style scoped>
.shadow-up {
  box-shadow: 0px -2px 4px -1px
      var(--v-shadow-key-umbra-opacity, rgba(0, 0, 0, 0.2)),
    0px -4px 5px 0px var(--v-shadow-key-penumbra-opacity, rgba(0, 0, 0, 0.14)),
    0px -1px 10px 0px var(--v-shadow-key-penumbra-opacity, rgba(0, 0, 0, 0.12));
  background-image: url("/public/img/footer.jpg");
  background-size: cover;
}
.footer-btn-text {
  text-decoration: none;
  font-size: 16px;
}
</style>

<template>
  <v-footer class="position-relative shadow-up flex-grow-0">
    <v-row class="footer-row" justify="center" no-gutters>
      <router-link :to="{ name: 'privacy' }">
        <v-btn color="white" variant="text">
          <span class="text-white footer-btn-text">Datenschutzerklärung</span>
        </v-btn>
      </router-link>
      <router-link :to="{ name: 'imprint' }">
        <v-btn color="white" variant="text">
          <span class="text-white footer-btn-text">Impressum</span>
        </v-btn>
      </router-link>
    </v-row>
  </v-footer>
</template>

<script>
export default {
  name: "PageFooter",
};
</script>
