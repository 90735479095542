<style scoped>
#map {
  width: 100%;
  height: 100%;
}
.map-wrapper {
  width: 100%;
  height: 275px;
  padding: 4px;
  border-image: url("/public/img/border.jpg") 4 / 4px / 0 round;
}
</style>

<template>
  <div class="map-wrapper elevation-4">
    <div id="map"></div>
  </div>
</template>

<script>
import L from 'leaflet';

export default {
  mounted() {
    const coords = [53.49427, 9.13679];
    const map = L.map('map').setView(coords, 13);

    L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 19,
      attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
    }).addTo(map);

    L.marker(coords).addTo(map);
  }
}
</script>
