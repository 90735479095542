import { createApp } from 'vue'
import App from './App.vue'
import HomeView from './views/Home.vue'
import PrivacyView from './views/Privacy.vue'
import ImprintView from './views/Imprint.vue'
import { createRouter, createWebHistory } from 'vue-router';
import "@fontsource/roboto/400.css";
import "leaflet/dist/leaflet.css";
import '@mdi/font/css/materialdesignicons.css';

const routes = [
  { path: '/', name: 'home', component: HomeView },
  { path: '/datenschutz', name: 'privacy', component: PrivacyView },
  { path: '/impressum', name: 'imprint', component: ImprintView },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

const vuetify = createVuetify({
  components,
  directives,
})

createApp(App).use(vuetify).use(router).mount('#app')

